class AvatarPets{
    constructor({petName, petType, petDesc, petId}){
            this.petName = petName
            this.petType = petType
            this.petDesc = petDesc
            this.petId = petId
    }

    get(){
        return{
            petId: this.petId,
            petName: this.petName,
            petType: this.petType,
            petDesc: (this.petDesc == '') ? '-' : this.petDesc,
        }
    }
}

export default AvatarPets