import React, { useRef } from 'react';
import DefaultForm from 'components/form/DefaultForm';
import { useDispatch } from 'react-redux';
import { fetchTransactionsFilters } from 'store/modules/transactions/transactionActions';

const TransactionsForm = ({ onFormChange, defaultValues }) => {
    const formRef = useRef();
    const dispatch = useDispatch();

    const transactionTypes = [
        { value: '', name: 'Todas' },
        { value: 'catalog', name: 'Catalog' },
        { value: 'gift', name: 'Gift' },
        { value: 'gold', name: 'Gold' },
        { value: 'item.instance', name: 'Item Instance' },
        { value: 'marketplace', name: 'Marketplace' },
        { value: 'mission', name: 'Mission' },
        { value: 'refund', name: 'Refund' },
        { value: 'seed', name: 'Seed' },
        { value: 'space', name: 'Space' },
        { value: 'spintowin', name: 'Spin to Win' },
        { value: 'trade', name: 'Trade' },
    ];

    const mapTransactionType = (value) => {
        const type = transactionTypes.find((type) => type.value === value);
        return type ? { name: type.name, value: type.value } : { name: '', value: '' };
    };

    const handleSubmit = (formData, isValid, name, value) => {
        onFormChange({
            form: { ...formData },
            isValid,
        });

        const newValue = name === 'transactionType' ? { [name]: mapTransactionType(value) } : { [name]: value };

        fetchTransactionsFilters(dispatch, { ...defaultValues, ...newValue });
    };

    const fields = [
        {
            label: 'Remetente',
            name: 'sender',
            value: defaultValues.sender ?? '',
            placeholder: 'Remetente',
            type: 'text',
            gridClass: 'col-span-4'
        },

        {
            label: 'Destinatário',
            name: 'receiver',
            value: defaultValues.receiver ?? '',
            placeholder: 'Destinatário',
            type: 'text',
            gridClass: 'col-span-4'
        },
        {
            label: 'Descrição',
            name: 'desc',
            value: defaultValues.desc ?? '',
            placeholder: 'Descrição',
            type: 'text',
            gridClass: 'col-span-4'
        },
        {
            label: 'ID do Espaço',
            name: 'space_id',
            value: defaultValues.space_id ?? '',
            placeholder: 'ID do Espaço',
            type: 'text',
            gridClass: 'col-span-4'
        },
        {
            label: 'ID de Instância',
            name: 'space_instance_id',
            value: defaultValues.space_instance_id ?? '',
            placeholder: 'ID de Instância',
            type: 'text',
            gridClass: 'col-span-4'
        },
        {
            label: 'ID de Item',
            name: 'item_id',
            value: defaultValues.item_id ?? '',
            placeholder: 'ID de Item',
            type: 'text',
            gridClass: 'col-span-4'
        },
        {
            name: 'dateFrom',
            label: 'De',
            value: defaultValues.dateFrom ?? '',
            type: 'date',
            gridClass: 'col-span-4'
        },
        {
            name: 'dateTo',
            label: 'Até',
            value: defaultValues.dateTo ?? '',
            type: 'date',
            gridClass: 'col-span-4'
        },
        {
            name: 'transactionType',
            label: 'Tipo de Transação',
            value: defaultValues.transactionType?.value ?? '',
            type: 'select',
            items: transactionTypes,
            gridClass: 'col-span-4',
        },
    ];

    return <DefaultForm ref={formRef} fields={fields} onChange={handleSubmit} />;
};

export default TransactionsForm;
