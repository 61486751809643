import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getAvatarUrl } from 'helpers'
import { Menu, Transition } from '@headlessui/react'
import styles from "../../styles/Header.module.css"
import {
    Bars3Icon,
    BellIcon,
    EnvelopeIcon,
    HomeModernIcon,
    IdentificationIcon,
    RocketLaunchIcon,
    UserGroupIcon,
    UserIcon
} from '@heroicons/react/24/outline'

import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoggedUser } from 'store/modules'
import { fetchAvatarCount } from 'store/modules/avatars/avatarCountSlice'
import { fetchAbuseReportCount } from 'store/modules/abuseReport/abuseReportCountSlice'
import SidebarMobile from './Sidebar/SidebarMobile'
import { useMainNavigation } from 'data/navigation'

export default function Header({ userNavigation, setSidebarOpen }) {
    const dispatch = useDispatch()

    const getLoggedUser = () => {
        dispatch(fetchLoggedUser())
    }

    const loggedUser = useSelector((state) => {
        return typeof state.loggedUser === 'object' ? state.loggedUser : {}
    })

    useEffect(() => {
        getLoggedUser()
    }, [])

    const avatarCount = useSelector((state) => {
        return state.avatarCount;
    })

    const abuseReportCount = useSelector((state) => {
        return state.abuseReportCount
    })

    useEffect(() => {
        fetchAvatarCount(dispatch);
        fetchAbuseReportCount(dispatch);
        const interval = setInterval(() => {
            fetchAbuseReportCount(dispatch);
            fetchAvatarCount(dispatch);
        }, 10000)
        return () => { clearInterval(interval) }
    }, [])

    const mainNavigation = useMainNavigation()

    return (

        <>
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 bg-cyan-800 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <button type="button" className="-m-2.5 p-2.5 text-white lg:hidden" onClick={() => setSidebarOpen(true)}>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <div className="relative flex flex-1">

                    </div>
                    <div className="flex items-center gap-x-2 lg:gap-x-4">
                        <button type="button" className="flex space-x-2 -m-2.5 p-2.5 text-white hover:text-white">
                            <IdentificationIcon className="h-6 w-6" aria-hidden="true" />
                            <span>{abuseReportCount[0]}</span>
                        </button>
                        <button type="button" className="flex space-x-2 -m-2.5 p-2.5 text-white hover:text-white">
                            <UserIcon className="h-6 w-6" aria-hidden="true" />
                            <span>{abuseReportCount[1]}</span>
                        </button>
                        <button type="button" className="flex space-x-2 -m-2.5 p-2.5 text-white hover:text-white">
                            <HomeModernIcon className="h-6 w-6" aria-hidden="true" />
                            <span>{abuseReportCount[2]}</span>
                        </button>
                        <button type="button" className="flex space-x-2 -m-2.5 p-2.5 text-white hover:text-white">
                            <RocketLaunchIcon className="h-6 w-6" aria-hidden="true" />
                            <span>{abuseReportCount[3]}</span>
                        </button>
                        <button type="button" className="flex space-x-2 -m-2.5 p-2.5 text-white hover:text-white">
                            <UserGroupIcon className="h-6 w-6" aria-hidden="true" />
                            <span>{abuseReportCount[4]}</span>
                        </button>
                        <button type="button" className="flex space-x-2 -m-2.5 p-2.5 text-white hover:text-white">
                            <EnvelopeIcon className="h-6 w-6" aria-hidden="true" />
                            <span>{abuseReportCount[5]}</span>
                        </button>

                        {/* Separator */}
                        <div className={styles.separator} aria-hidden="true" />

                        <div className="flex space-x-2 -m-2.5 p-2.5 text-white hover:text-white">
                            <span>{avatarCount} Avatares nos espaços</span>
                        </div>

                        {/* Separator */}
                        <div className={styles.separator} aria-hidden="true" />

                        {/* Profile dropdown */}
                        {loggedUser && (
                            <Menu as="div" className="relative">
                                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                    <span className="sr-only">Open user menu</span>
                                    <img
                                        className="h-8 w-8 rounded-full bg-gray-50"
                                        src={getAvatarUrl(loggedUser)}
                                        alt=""
                                    />
                                    <span className="hidden lg:flex lg:items-center">
                                        <span className="ml-4 text-sm font-semibold leading-6 text-white" aria-hidden="true">
                                            {loggedUser.firstName} {loggedUser.lastName}
                                        </span>
                                        <ChevronDownIcon className="ml-2 h-5 w-5 text-white" aria-hidden="true" />
                                    </span>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-64 origin-top-right rounded-t-none mt-4 rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                            <Menu.Item key={item.name}>
                                                {
                                                    item.action ? (
                                                        <a
                                                            onClick={item.action}
                                                            className={classNames(
                                                                'block px-3 py-1 text-sm leading-6 text-grey-600 cursor-pointer'
                                                            )}
                                                        >
                                                            {item.name}
                                                        </a>
                                                    ) : (
                                                        <Link to={item.to} className={'block px-3 py-1 text-sm leading-6 text-grey-600 cursor-pointer'}>
                                                            {item.name}
                                                        </Link>
                                                    )
                                                }
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        )}

                    </div>
                </div>
            </div>
        </>
    )

}
