import React, { useEffect, useState } from 'react'
import Tabs from 'components/Tabs'
import BasicTable from 'components/BasicTable'
import style from '../../../../styles/UserTabs.module.css'
import styles from '../../../../styles/Search.module.css'
import { users, logins, flag } from 'data/users'
import { UseAvatarOptions, UseAvatarProfileProperties, UseAvatarProperties, UseAvatarXps } from './avatarSearch'
import Modal from 'components/Modal'
import Button from 'components/form/Button'
import { deletePet, getAvatarXp, saveAvatar, saveAvatarProfileField, saveAvatarProfileText, saveAvatarXp } from 'services/avatarsService'
import { fetchSelectedAvatarDetails, fetchSelectedAvatarProfileDetails } from 'store/modules/users/avatars/selectedAvatarDetailSlice'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { fetchXpLogs } from 'store/modules/users/avatars/xpLogsSlice'
import XpFilter from './XpFilter'
import ButtonCss from 'components/BasicTable/ButtonCss'
import Check from '../../../../img/check-mark.png'
import Close from '../../../../img/close.png'
import Arena from '../../../../img/Skills/arenaSkill.png'
import Artist from '../../../../img/Skills/artistSkill.png'
import Crafting from '../../../../img/Skills/craftingSkill.png'
import Explorer from '../../../../img/Skills/explorerSkill.png'
import Farmer from '../../../../img/Skills/farmerSkill.png'
import Gamer from '../../../../img/Skills/gamerSkill.png'
import Social from '../../../../img/Skills/socialSkill.png'
import Primary from '../../../../img/Skills/primarySkill.png'
import Edit from '../../../../img//edit.png'
import LevelCalc from './LevelCalc'
import { fetchPets } from 'store/modules/users/avatars/petsSlice'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'



export default function Users({ item }) {
    const verifyAvatarXp = useUserPermission(SMIPermissionsCode.MOD_AVATAR_XP);
    const verifyAvatarExpChange = useUserPermission(SMIPermissionsCode.MOD_AVATAR_EXP_CHANGE);
    const verifyAvatarPets = useUserPermission(SMIPermissionsCode.MOD_AVATAR_PETS);
    const verifyAvatarConfig = useUserPermission(SMIPermissionsCode.MOD_AVATAR_CONFIG_VIEW)
    const verifyAvatarConfigEdit = useUserPermission(SMIPermissionsCode.MOD_AVATAR_CONFIG_EDIT)

    //ABA PROPRIEDADE
    const avatarsHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const profileHeaders = [
        { title: 'Perfil', key: 'name' },
        { title: '', key: 'value', selectable: true },
    ]

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")

    const firstAvatarPropertiesData = UseAvatarProperties(item);
    const [avatarPropertiesData, setAvatarPropertiesData] = useState(firstAvatarPropertiesData)
    const firstAvatarProfilePropertiesData = UseAvatarProfileProperties(item);
    const [avatarProfilePropertiesData, setAvatarProfilePropertiesData] = useState(firstAvatarProfilePropertiesData)

    const avatarStatusOptions = UseAvatarOptions(1);
    const [statusOptions, setStatusOptions] = useState([])
    useEffect(() => {
        if (avatarStatusOptions != null) {
            avatarStatusOptions.options.push({ id: 0, desc: '-' })

            avatarStatusOptions.options.sort((a, b) => {
                if (a.desc.toLowerCase() < b.desc.toLowerCase()) {
                    return -1;
                }
                if (a.desc.toLowerCase() > b.desc.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }
        setStatusOptions(avatarStatusOptions)
    }, [avatarStatusOptions]);

    const avatarLanguageOptions = UseAvatarOptions(2);
    const [languageOptions, setLanguageOptions] = useState([])
    useEffect(() => {
        if (avatarLanguageOptions != null) {
            avatarLanguageOptions.options.push({ id: 0, desc: '-' })

            avatarLanguageOptions.options.sort((a, b) => {
                if (a.desc.toLowerCase() < b.desc.toLowerCase()) {
                    return -1;
                }
                if (a.desc.toLowerCase() > b.desc.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }
        setLanguageOptions(avatarLanguageOptions)
    }, [avatarLanguageOptions]);

    const avatarLocalOptions = UseAvatarOptions(3);
    const [localOptions, setLocalOptions] = useState([])
    useEffect(() => {
        if (avatarLocalOptions != null) {
            avatarLocalOptions.options.push({ id: 0, desc: '-' })

            avatarLocalOptions.options.sort((a, b) => {
                if (a.desc.toLowerCase() < b.desc.toLowerCase()) {
                    return -1;
                }
                if (a.desc.toLowerCase() > b.desc.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }

        setLocalOptions(avatarLocalOptions);
    }, [avatarLocalOptions]);

    const avatarOccupationOptions = UseAvatarOptions(4);
    const [occupationOptions, setOccupationOptions] = useState([])
    useEffect(() => {
        if (avatarOccupationOptions != null) {
            avatarOccupationOptions.options.push({ id: 0, desc: '-' })

            avatarOccupationOptions.options.sort((a, b) => {
                if (a.desc.toLowerCase() < b.desc.toLowerCase()) {
                    return -1;
                }
                if (a.desc.toLowerCase() > b.desc.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }

        setOccupationOptions(avatarOccupationOptions);
    }, [avatarOccupationOptions]);

    const avatarEducationOptions = UseAvatarOptions(5);
    const [educationOptions, setEducationOptions] = useState([])
    useEffect(() => {
        if (avatarEducationOptions != null) {
            avatarEducationOptions.options.push({ id: 0, desc: '-' })

            avatarEducationOptions.options.sort((a, b) => {
                if (a.desc.toLowerCase() < b.desc.toLowerCase()) {
                    return -1;
                }
                if (a.desc.toLowerCase() > b.desc.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }

        setEducationOptions(avatarEducationOptions);
    }, [avatarEducationOptions]);

    const avatarSignOptions = UseAvatarOptions(6);
    const [signOptions, setSignOptions] = useState([])
    useEffect(() => {
        if (avatarSignOptions != null) {
            avatarSignOptions.options.push({ id: 0, desc: '-' })

            avatarSignOptions.options.sort((a, b) => {
                if (a.desc.toLowerCase() < b.desc.toLowerCase()) {
                    return -1;
                }
                if (a.desc.toLowerCase() > b.desc.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }

        setSignOptions(avatarSignOptions);
    }, [avatarSignOptions]);


    useEffect(() => {
        let firstAvatarPropertiesDataCopy = { ...firstAvatarPropertiesData }
        setAvatarPropertiesData(firstAvatarPropertiesDataCopy)

        let firstAvatarProfilePropertiesDataCopy = { ...firstAvatarProfilePropertiesData }
        setAvatarProfilePropertiesData(firstAvatarProfilePropertiesDataCopy)

        setOriginalValues({
            avatarId: firstAvatarPropertiesData.avatarId,
            avatarFirstName: firstAvatarPropertiesData.avatarFirstName,
            avatarLastName: firstAvatarPropertiesData.avatarLastName,
            avatarNameInstance: firstAvatarPropertiesData.avatarNameInstance,
            timeInSpaces: firstAvatarPropertiesData.timeInSpaces,
            socialStatus: firstAvatarProfilePropertiesData.socialStatus,
            language: firstAvatarProfilePropertiesData.language,
            location: firstAvatarProfilePropertiesData.location,
            occupation: firstAvatarProfilePropertiesData.occupation,
            education: firstAvatarProfilePropertiesData.education,
            starSign: firstAvatarProfilePropertiesData.starSign,
            aboutMe: firstAvatarProfilePropertiesData.aboutMe,
            contactMeIf: firstAvatarProfilePropertiesData.contactMeIf
        })

        setChangedValues({
            avatarId: firstAvatarPropertiesData.avatarId,
            avatarFirstName: firstAvatarPropertiesData.avatarFirstName,
            avatarLastName: firstAvatarPropertiesData.avatarLastName,
            avatarNameInstance: firstAvatarPropertiesData.avatarNameInstance,
            timeInSpaces: firstAvatarPropertiesData.timeInSpaces,
            socialStatus: firstAvatarProfilePropertiesData.socialStatus,
            language: firstAvatarProfilePropertiesData.language,
            location: firstAvatarProfilePropertiesData.location,
            occupation: firstAvatarProfilePropertiesData.occupation,
            education: firstAvatarProfilePropertiesData.education,
            starSign: firstAvatarProfilePropertiesData.starSign,
            aboutMe: firstAvatarProfilePropertiesData.aboutMe,
            contactMeIf: firstAvatarProfilePropertiesData.contactMeIf
        })

    }, [firstAvatarPropertiesData, firstAvatarProfilePropertiesData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name != "ID do Avatar" && item.name != 'Tempo Total em Espaços') {
            setCurrentItem(item);
            setModalTitle(item.name);
            setInputValue(item.value);
            handleOpenModal();
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value);
    }

    const handleSubmit = () => {
        let avatarPropertiesDataCopy = { ...avatarPropertiesData, tableProperties: [...avatarPropertiesData.tableProperties] };
        let avatarProfilePropertiesDataCopy = { ...avatarProfilePropertiesData, tableProperties: [...avatarProfilePropertiesData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        console.log(inputValue)

        if (inputValue != '') {
            for (let i = 0; i < avatarPropertiesData.tableProperties.length; i++) {
                if (avatarPropertiesData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    avatarPropertiesDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    avatarPropertiesDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                }
            }
            for (let i = 0; i < avatarProfilePropertiesData.tableProperties.length; i++) {
                if (avatarProfilePropertiesData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    avatarProfilePropertiesDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    avatarProfilePropertiesDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                }
            }
            setChangedValues(changedValuesCopy)
            setAvatarPropertiesData(avatarPropertiesDataCopy);
            setAvatarProfilePropertiesData(avatarProfilePropertiesDataCopy);

        }

        if ((inputValue == '' && modalTitle == 'Sobre mim') || (inputValue == '' && modalTitle == 'Me contate se...')) {
            for (let i = 0; i < avatarPropertiesData.tableProperties.length; i++) {
                if (avatarPropertiesData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    avatarPropertiesDataCopy.tableProperties[i] = { name: currentItem.name, value: '-', key: currentItem.key };
                    avatarPropertiesDataCopy[currentItem.key] = '-';
                    changedValuesCopy[currentItem.key] = '-';
                }
            }
            for (let i = 0; i < avatarProfilePropertiesData.tableProperties.length; i++) {
                if (avatarProfilePropertiesData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    avatarProfilePropertiesDataCopy.tableProperties[i] = { name: currentItem.name, value: '-', key: currentItem.key };
                    avatarProfilePropertiesDataCopy[currentItem.key] = '-';
                    changedValuesCopy[currentItem.key] = '-';
                }
            }
            setChangedValues(changedValuesCopy)
            setAvatarPropertiesData(avatarPropertiesDataCopy);
            setAvatarProfilePropertiesData(avatarProfilePropertiesDataCopy);
        }
        setCurrentItem({});
        setInputValue("");
        handleCloseModal();

    }

    const handleVerification = () => {
        if (modalTitle == 'Status Social') {
            return (
                <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                    {statusOptions && statusOptions.options && statusOptions.options.length > 0 ? (
                        statusOptions.options.map((item, index) => (
                            <option key={item.id} value={item.desc}>
                                {item.desc}
                            </option>
                        ))
                    ) : (
                        <option disabled>Sem opções</option>
                    )}
                </select>
            )
        }
        else if (modalTitle == 'Idioma') {
            return (
                <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                    {languageOptions && languageOptions.options && languageOptions.options.length > 0 ? (
                        languageOptions.options.map((item, index) => (
                            <option key={item.id} value={item.desc}>
                                {item.desc}
                            </option>
                        ))
                    ) : (
                        <option disabled>Sem opções</option>
                    )}
                </select>
            )
        }
        else if (modalTitle == 'Local') {
            return (
                <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                    {localOptions && localOptions.options && localOptions.options.length > 0 ? (
                        localOptions.options.map((item, index) => (
                            <option key={item.id} value={item.desc}>
                                {item.desc}
                            </option>
                        ))
                    ) : (
                        <option disabled>Sem opções</option>
                    )}
                </select>
            )
        }
        else if (modalTitle == 'Ocupação') {
            return (
                <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                    {occupationOptions && occupationOptions.options && occupationOptions.options.length > 0 ? (
                        occupationOptions.options.map((item, index) => (
                            <option key={item.id} value={item.desc}>
                                {item.desc}
                            </option>
                        ))
                    ) : (
                        <option disabled>Sem opções</option>
                    )}
                </select>
            )
        }
        else if (modalTitle == 'Educação') {
            return (
                <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                    {educationOptions && educationOptions.options && educationOptions.options.length > 0 ? (
                        educationOptions.options.map((item, index) => (
                            <option key={item.id} value={item.desc}>
                                {item.desc}
                            </option>
                        ))
                    ) : (
                        <option disabled>Sem opções</option>
                    )}
                </select>
            )
        }
        else if (modalTitle == 'Signo') {
            return (
                <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                    {signOptions && signOptions.options && signOptions.options.length > 0 ? (
                        signOptions.options.map((item, index) => (
                            <option key={item.id} value={item.desc}>
                                {item.desc}
                            </option>
                        ))
                    ) : (
                        <option disabled>Sem opções</option>
                    )}
                </select>
            )
        }
        else {
            return (
                (modalTitle != 'Tempo Total em espaços' && modalTitle != 'Instância do Avatar') ?
                    <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                    : <input className={style.modalInput} type="number" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
            )
        }
    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues });
        setAvatarPropertiesData(firstAvatarPropertiesData);
        setAvatarProfilePropertiesData(firstAvatarProfilePropertiesData);

    }

    const dispatch = useDispatch();

    const handleSaveChanges = async () => {
        try {


            let originalValuesCopy = { ...originalValues };
            let changedValuesCopy = { ...changedValues };
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            let statusValue, languageValue, locationValue, occupationValue, educationValue, starSignValue;

            if (statusOptions && statusOptions.options && statusOptions.options.length > 0) {
                for (let i = 0; i < statusOptions.options.length; i++) {
                    if (changedValues.socialStatus == statusOptions.options[i].desc) {
                        statusValue = statusOptions.options[i].id;
                    }
                }
            }

            if (languageOptions && languageOptions.options && languageOptions.options.length > 0) {
                for (let i = 0; i < languageOptions.options.length; i++) {
                    if (changedValues.language == languageOptions.options[i].desc) {
                        languageValue = languageOptions.options[i].id;
                    }
                }
            }

            if (localOptions && localOptions.options && localOptions.options.length > 0) {
                for (let i = 0; i < localOptions.options.length; i++) {
                    if (changedValues.location == localOptions.options[i].desc) {
                        locationValue = localOptions.options[i].id;
                    }
                }
            }

            if (occupationOptions && occupationOptions.options && occupationOptions.options.length > 0) {
                for (let i = 0; i < occupationOptions.options.length; i++) {
                    if (changedValues.occupation == occupationOptions.options[i].desc) {
                        occupationValue = occupationOptions.options[i].id;
                    }
                }
            }

            if (educationOptions && educationOptions.options && educationOptions.options.length > 0) {
                for (let i = 0; i < educationOptions.options.length; i++) {
                    if (changedValues.education == educationOptions.options[i].desc) {
                        educationValue = educationOptions.options[i].id;
                    }
                }
            }

            if (signOptions && signOptions.options && signOptions.options.length > 0) {
                for (let i = 0; i < signOptions.options.length; i++) {
                    if (changedValues.starSign == signOptions.options[i].desc) {
                        starSignValue = signOptions.options[i].id;
                    }
                }
            }

            const fieldValue = {
                socialStatus: statusValue,
                language: languageValue,
                location: locationValue,
                occupation: occupationValue,
                education: educationValue,
                starSign: starSignValue,
            }

            const fieldId = {
                socialStatus: 1,
                language: 2,
                location: 3,
                occupation: 4,
                education: 5,
                starSign: 6,
            }

            const textProperty = {
                aboutMe: 0,
                contactMeIf: 1,
            }

            const apiProperty = {
                avatarFirstName: 'firstName',
                avatarLastName: 'lastName',
                avatarNameInstance: 'nameInstance',
                timeInSpaces: 'timeInSpaces',
            }

            let changes = {};
            let dataFieldChanges = {};
            let textDataFieldChanges = {};

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if ((originalValues[propName] !== changedValues[propName]) && (propName == 'aboutMe' || propName == 'contactMeIf')) {
                    textDataFieldChanges[textProperty[propName]] = changedValues[propName];
                }
            }

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if ((originalValues[propName] !== changedValues[propName]) && (propName == 'socialStatus' || propName == 'language' || propName == 'location' || propName == 'occupation' || propName == 'education' || propName == 'starSign')) {
                    dataFieldChanges[fieldId[propName]] = fieldValue[propName];
                }
            }

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if ((originalValues[propName] !== changedValues[propName]) && apiProperty[propName] != undefined) {
                    changes[apiProperty[propName]] = changedValues[propName];
                }
            }

            if (changes != {}) {
                await saveAvatar(item.avatarId, changes);
            }


            if (dataFieldChanges != {}) {
                await saveAvatarProfileField(item.avatarId, dataFieldChanges);
            }

            if (textDataFieldChanges != {}) {
                if (textDataFieldChanges[0] == '-') {
                    textDataFieldChanges[0] = null;
                }
                if (textDataFieldChanges[1] == '-') {
                    textDataFieldChanges[1] = null;
                }

                await saveAvatarProfileText(item.avatarId, textDataFieldChanges);
            }

            setChangedValues(changedValuesCopy);
            setOriginalValues(changedValuesCopy);

            fetchSelectedAvatarDetails(dispatch, { avatarId: item.avatarId });
            fetchSelectedAvatarProfileDetails(dispatch, { avatarId: item.avatarId });

            toast.success('Dados salvos com Sucesso!');
        }
        catch (err) {
            toast.error('Erro ao salvar!')
            console.log(err)
        }

    }

    //ABA XP

    const xpHeaders = [
        { title: 'Data', key: 'date' },
        { title: 'XP', key: 'xpType' },
        { title: 'Descrição', key: 'desc' },
        { title: 'Valor', key: 'value' }
    ]

    const [xpFilter, setXpFilter] = useState({});
    const [isLoading, setLoading] = useState(false);
    const avatarXp = UseAvatarXps(item.avatarId);

    const [originalXpValues, setOriginalXpValues] = useState({ artist: 0, arena: 0, crafting: 0, explorer: 0, farmer: 0, gamer: 0, social: 0 });
    const [changedXpValues, setChangedXpValues] = useState({ artist: 0, arena: 0, crafting: 0, explorer: 0, farmer: 0, gamer: 0, social: 0 });

    const [artistLevel, setArtistLevel] = useState(0);
    const [artistValue, setArtistValue] = useState(0);
    const [artistInput, setArtistInput] = useState("");
    const [artistValueCopy, setArtistValueCopy] = useState(0);


    const [arenaLevel, setArenaLevel] = useState(0);
    const [arenaValue, setArenaValue] = useState(0);
    const [arenaInput, setArenaInput] = useState("");
    const [arenaValueCopy, setArenaValueCopy] = useState(0);

    const [craftingLevel, setCraftingLevel] = useState(0);
    const [craftingValue, setCraftingValue] = useState(0);
    const [craftingInput, setCraftingInput] = useState("");
    const [craftingValueCopy, setCraftingValueCopy] = useState(0);

    const [explorerLevel, setExplorerLevel] = useState(0);
    const [explorerValue, setExplorerValue] = useState(0);
    const [explorerInput, setExplorerInput] = useState("");
    const [explorerValueCopy, setExplorerValueCopy] = useState(0);

    const [farmerLevel, setFarmerLevel] = useState(0);
    const [farmerValue, setFarmerValue] = useState(0);
    const [farmerInput, setFarmerInput] = useState("");
    const [farmerValueCopy, setFarmerValueCopy] = useState(0);

    const [gamerLevel, setGamerLevel] = useState(0);
    const [gamerValue, setGamerValue] = useState(0);
    const [gamerInput, setGamerInput] = useState("");
    const [gamerValueCopy, setGamerValueCopy] = useState(0);

    const [socialLevel, setSocialLevel] = useState(0);
    const [socialValue, setSocialValue] = useState(0);
    const [socialInput, setSocialInput] = useState("");
    const [socialValueCopy, setSocialValueCopy] = useState(0);

    const [primaryLevel, setPrimaryLevel] = useState(0);
    const [primaryValue, setPrimaryValue] = useState(0);

    const [handleXpValueChange, setHandleXpValueChange] = useState(false)

    const [isArtistOpen, setIsArtistOpen] = useState(false);
    const [isArenaOpen, setIsArenaOpen] = useState(false);
    const [isCraftingOpen, setIsCraftingOpen] = useState(false);
    const [isExplorerOpen, setIsExplorerOpen] = useState(false);
    const [isFarmerOpen, setIsFarmerOpen] = useState(false);
    const [isGamerOpen, setIsGamerOpen] = useState(false);
    const [isSocialOpen, setIsSocialOpen] = useState(false);

    useEffect(() => {
        if (avatarXp != null) {
            setArtistValue(0);
            setArenaValue(0);
            setCraftingValue(0);
            setExplorerValue(0);
            setFarmerValue(0);
            setGamerValue(0);
            setSocialValue(0);
            setPrimaryValue(0);
            for (let i = 0; i < avatarXp.length; i++) {
                if (avatarXp[i].xpType == 'Artist') {
                    setArtistValue(avatarXp[i].value);
                }
                if (avatarXp[i].xpType == 'Arena') {
                    setArenaValue(avatarXp[i].value);
                }
                if (avatarXp[i].xpType == 'Crafting') {
                    setCraftingValue(avatarXp[i].value);
                }
                if (avatarXp[i].xpType == 'Explorer') {
                    setExplorerValue(avatarXp[i].value);
                }
                if (avatarXp[i].xpType == 'Farmer') {
                    setFarmerValue(avatarXp[i].value);
                }
                if (avatarXp[i].xpType == 'Gamer') {
                    setGamerValue(avatarXp[i].value);
                }
                if (avatarXp[i].xpType == 'Social') {
                    setSocialValue(avatarXp[i].value);
                }
                if (avatarXp[i].xpType == 'Primary') {
                    setPrimaryValue(avatarXp[i].value);
                }
            }
        }
    }, [avatarXp]);

    useEffect(() => {
        setOriginalXpValues({ artist: artistValue, arena: arenaValue, crafting: craftingValue, explorer: explorerValue, farmer: farmerValue, gamer: gamerValue, social: socialValue });
        setChangedXpValues({ artist: artistValue, arena: arenaValue, crafting: craftingValue, explorer: explorerValue, farmer: farmerValue, gamer: gamerValue, social: socialValue });

        setArtistValueCopy(artistValue);
        setArenaValueCopy(arenaValue);
        setCraftingValueCopy(craftingValue);
        setExplorerValueCopy(explorerValue);
        setFarmerValueCopy(farmerValue);
        setGamerValueCopy(gamerValue);
        setSocialValueCopy(socialValue);

        setArtistLevel(LevelCalc(artistValue, 'artist'));
        setArenaLevel(LevelCalc(arenaValue, 'arena'));
        setCraftingLevel(LevelCalc(craftingValue, 'crafting'));
        setExplorerLevel(LevelCalc(explorerValue, 'explorer'));
        setFarmerLevel(LevelCalc(farmerValue, 'farmer'));
        setGamerLevel(LevelCalc(gamerValue, 'gamer'));
        setSocialLevel(LevelCalc(socialValue, 'social'));
        setPrimaryLevel(LevelCalc(primaryValue, 'primary'));

    }, [artistValue, arenaValue, craftingValue, explorerValue, farmerValue, gamerValue, socialValue])

    const xpLogs = useSelector((state) => {
        return Array.isArray(state.xpLogs.data) ? state.xpLogs.data : []
    })

    const skip = useSelector((state) => {
        return (state.xpLogs.currentSkip) ? state.xpLogs.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.xpLogs.hasMore)
    })
    const xpFilters = useSelector((state) => {
        return Object(state.xpLogs.filters) ? state.xpLogs.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [xpLogs]);

    useEffect(() => {
        setLoading(true)
        fetchXpLogs(dispatch, xpFilter.form, 0, item.userId, item.avatarId);
    }, [item])

    const search = () => {
        setLoading(true)
        fetchXpLogs(dispatch, xpFilter.form, 0, item.userId, item.avatarId);
        console.log(item)

    }
    const handleSetFilter = ({ form, isValid }) => {
        if (verifyAvatarExpChange) {
            setXpFilter({ form, isValid })
        }
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchXpLogs(dispatch, xpFilter.form, skip + 200, item.userId, item.avatarId);
    })

    const handleOnChangeArtistInput = ((e) => {
        if (verifyAvatarExpChange) {
            setArtistInput(e.target.value)
        }
    })
    const handleOnChangeArenaInput = ((e) => {
        if (verifyAvatarExpChange) {
            setArenaInput(e.target.value)
        }
    })
    const handleOnChangeCraftingInput = ((e) => {
        if (verifyAvatarExpChange) {
            setCraftingInput(e.target.value)
        }
    })
    const handleOnChangeExplorerInput = ((e) => {
        if (verifyAvatarExpChange) {
            setExplorerInput(e.target.value)
        }
    })
    const handleOnChangeFarmerInput = ((e) => {
        if (verifyAvatarExpChange) {
            setFarmerInput(e.target.value)
        }
    })
    const handleOnChangeGamerInput = ((e) => {
        if (verifyAvatarExpChange) {
            setGamerInput(e.target.value)
        }
    })
    const handleOnChangeSocialInput = ((e) => {
        if (verifyAvatarExpChange) {
            setSocialInput(e.target.value)
        }
    })

    const handleChange = ((type, value) => {
        if (verifyAvatarExpChange) {
            if (value != '' && Number(value) != changedXpValues[type]) {
                const changedXpValuesCopy = changedXpValues
                setChangedXpValues({ ...changedXpValuesCopy, [type]: value })
                if (type == 'artist') {
                    setArtistValueCopy(value);
                    setIsArtistOpen(false);
                    setArtistInput("");
                }
                if (type == 'arena') {
                    setArenaValueCopy(value);
                    setIsArenaOpen(false);
                    setArenaInput("");
                }
                if (type == 'crafting') {
                    setCraftingValueCopy(value);
                    setIsCraftingOpen(false);
                    setCraftingInput("")
                }
                if (type == 'explorer') {
                    setExplorerValueCopy(value);
                    setIsExplorerOpen(false);
                    setExplorerInput("");
                }
                if (type == 'farmer') {
                    setFarmerValueCopy(value);
                    setIsFarmerOpen(false);
                    setFarmerInput("");
                }
                if (type == 'gamer') {
                    setGamerValueCopy(value);
                    setIsGamerOpen(false);
                    setGamerInput("");
                }
                if (type == 'social') {
                    setSocialValueCopy(value);
                    setIsSocialOpen(false);
                    setSocialInput("");
                }
            }
        }
    })

    useEffect(() => {
        let originalXpValuesProps = Object.getOwnPropertyNames(originalXpValues);
        let changedXpValuesProps = Object.getOwnPropertyNames(changedXpValues);

        console.log(originalXpValues)
        console.log(changedXpValues)

        if (originalXpValuesProps.length != changedXpValuesProps.length) {
            setHandleXpValueChange(false)
            return;
        }

        for (let i = 0; i < originalXpValuesProps.length; i++) {
            let propName = originalXpValuesProps[i];

            if (originalXpValues[propName] !== changedXpValues[propName]) {
                setHandleXpValueChange(false)
                return;
            }
        }

        setHandleXpValueChange(true)

    }, [item, changedXpValues])

    const handleDiscardXpChanges = (() => {
        setChangedXpValues({ ...originalXpValues });
        console.log(originalXpValues)
        setArtistValueCopy(originalXpValues.artist);
        setArenaValueCopy(originalXpValues.arena);
        setCraftingValueCopy(originalXpValues.crafting);
        setExplorerValueCopy(originalXpValues.explorer);
        setFarmerValueCopy(originalXpValues.farmer);
        setGamerValueCopy(originalXpValues.gamer);
        setSocialValueCopy(originalXpValues.social);
        setHandleXpValueChange(false);
    })

    const handleSaveXpChanges = async () => {
        try {
            let changedXpValuesCopy = { ...changedXpValues };
            let originalXpValuesProps = Object.getOwnPropertyNames(originalXpValues);

            const apiProperty = {
                artist: 1,
                arena: 5,
                crafting: 7,
                explorer: 2,
                farmer: 6,
                gamer: 3,
                social: 4,
            }


            let changes = {};

            for (let i = 0; i < originalXpValuesProps.length; i++) {
                let propName = originalXpValuesProps[i];
                if (originalXpValues[propName] !== changedXpValues[propName]) {
                    changes[apiProperty[propName]] = changedXpValues[propName];
                }
            }

            if (changes != {}) {
                await saveAvatarXp(item.avatarId, changes);
            }

            setChangedXpValues(changedXpValuesCopy);
            setOriginalXpValues(changedXpValuesCopy);

            setArtistLevel(LevelCalc(changedXpValuesCopy.artist, 'artist'));
            setArenaLevel(LevelCalc(changedXpValuesCopy.arena, 'arena'));
            setCraftingLevel(LevelCalc(changedXpValuesCopy.crafting, 'crafting'));
            setExplorerLevel(LevelCalc(changedXpValuesCopy.explorer, 'explorer'));
            setFarmerLevel(LevelCalc(changedXpValuesCopy.farmer, 'farmer'));
            setGamerLevel(LevelCalc(changedXpValuesCopy.gamer, 'gamer'));
            setSocialLevel(LevelCalc(changedXpValuesCopy.social, 'social'));

            fetchSelectedAvatarDetails(dispatch, { avatarId: item.avatarId });

            let newValue = await getAvatarXp(item.avatarId);
            console.log(newValue)
            for (let i = 0; i < newValue.length; i++) {
                if (newValue[i].xpType == 'Primary') {
                    setPrimaryValue(newValue[i].value);
                    setPrimaryLevel(LevelCalc(newValue[i].value, 'primary'))
                }
            }


            toast.success('Dados salvos com Sucesso!');
        }
        catch (err) {
            toast.error('Erro ao salvar!')
            console.log(err)
        }

    }

    //ABA PETS

    const petsHeaders = [
        { title: 'Nome', key: 'petName' },
        { title: 'Tipo', key: 'petType' },
        { title: 'Descrição', key: 'petDesc' },
    ]

    const [petsIsLoading, setPetsLoading] = useState(false);

    const pets = useSelector((state) => {
        return Array.isArray(state.pets.data) ? state.pets.data : []
    })

    const petsSkip = useSelector((state) => {
        return (state.pets.currentSkip) ? state.pets.currentSkip : 0
    })
    const petsHasMore = useSelector((state) => {
        return Boolean(state.pets.hasMore)
    })

    useEffect(() => {
        setPetsLoading(false)
    }, [pets]);

    useEffect(() => {
        setPetsLoading(true)
        fetchPets(dispatch, { avatarId: item.avatarId }, 0);
    }, [item])

    const petsHandleLoadMore = (() => {
        setPetsLoading(true);
        fetchPets(dispatch, { avatarId: item.avatarId }, skip + 200);
    })

    const handleDeletePet = async (pet) => {
        try{
            await deletePet(pet.petId)
            fetchPets(dispatch, { avatarId: item.avatarId }, 0);
            toast.success('Pet Deletado com Sucesso')
        }
        catch(err){
            toast.error('Erro ao Deletar Pet!')
            console.log(err)
        }
        
    }

    const tabs = [];

    tabs.push({
        id: 1,
        tabTitle: 'Propriedades',
        content: (
            <>
                <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-full p-2'>
                    <BasicTable
                        height='100%'
                        onSelectRow={handleSelectRow}
                        headers={avatarsHeaders}
                        items={avatarPropertiesData.tableProperties}
                        round={false}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                    />
                    <BasicTable
                        height='100%'
                        onSelectRow={handleSelectRow}
                        headers={profileHeaders}
                        items={avatarProfilePropertiesData.tableProperties}
                        round={false}
                    />
                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                            {handleVerification()}

                            <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                        </div>
                    </Modal>
                </div>
            </>
        )
    })

    if (verifyAvatarXp) {
        tabs.push({
            id: 2,
            tabTitle: 'XP',
            content: (
                <>
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>

                        {(handleXpValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveXpChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardXpChanges} color="cyan" />
                            </div>
                        }
                        <XpFilter onFilterChange={handleSetFilter} defaultValues={xpFilters} />
                        <BasicTable
                            height='30vh'
                            headers={xpHeaders}
                            items={xpLogs}
                            title='Buscar XP Logs'
                            isLoading={isLoading && xpLogs.length === 0}
                            slotActions={<Button disabled={!xpFilter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                            slotFooter={
                                <div className={styles.slotFooter}>
                                    <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                    {(xpLogs.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : xpLogs.length} resultados</span>}
                                </div>
                            }
                        />
                        <div className={style.avatarSkillsContainer}>
                            <div className={style.avatarSkillsTopContainer}>
                                <span className={style.skillXp}>Skill XP</span>
                                <div className={style.avatarSkillsTopContainerRow}>
                                    <div >
                                        <div className={style.skillItem}>
                                            <div className={style.skillTitle}>
                                                <img src={Artist} className={style.skillIcon} />
                                                <span>Artist Level: </span>
                                            </div>
                                            {isArtistOpen ?
                                                <div className={style.skillInfo}>
                                                    <input type='number' placeholder='Valor' className={style.skillInput} value={artistInput} onChange={handleOnChangeArtistInput} />
                                                    <ButtonCss onlyIcon={true} icon={Check} onClick={() => { handleChange('artist', artistInput) }} imgHeight='10px' color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setArtistInput(""); setIsArtistOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                                :
                                                <div className={style.skillInfo}>
                                                    <span className={style.skillInfoNum}>{artistLevel}</span>
                                                    <span>({<span className={style.skillInfoNum}>{artistValueCopy}</span>} xp)</span>
                                                    {verifyAvatarExpChange && <ButtonCss onlyIcon={true} icon={Edit} onClick={() => { setIsArtistOpen(true) }} imgHeight='15px' color='#155e75' height='18px' width='18px' isRound={true} />}
                                                </div>
                                            }
                                        </div>
                                        <div className={style.skillItem}>
                                            <div className={style.skillTitle}>
                                                <img src={Gamer} className={style.skillIcon} />
                                                <span>Gamer Level: </span>
                                            </div>
                                            {isGamerOpen ?
                                                <div className={style.skillInfo}>
                                                    <input type='number' placeholder='Valor' className={style.skillInput} value={gamerInput} onChange={handleOnChangeGamerInput} />
                                                    <ButtonCss onlyIcon={true} icon={Check} onClick={() => { handleChange('gamer', gamerInput) }} imgHeight='10px' color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setGamerInput(""); setIsGamerOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                                :
                                                <div className={style.skillInfo}>
                                                    <span className={style.skillInfoNum}>{gamerLevel}</span>
                                                    <span>({<span className={style.skillInfoNum}>{gamerValueCopy}</span>} xp)</span>
                                                    {verifyAvatarExpChange && <ButtonCss onlyIcon={true} icon={Edit} onClick={() => { setIsGamerOpen(true) }} imgHeight='15px' color='#155e75' height='18px' width='18px' isRound={true} />}
                                                </div>
                                            }
                                        </div>
                                        <div className={style.skillItem}>
                                            <div className={style.skillTitle}>
                                                <img src={Arena} className={style.skillIcon} />
                                                <span>Arena Level: </span>
                                            </div>
                                            {isArenaOpen ?
                                                <div className={style.skillInfo}>
                                                    <input type='number' placeholder='Valor' className={style.skillInput} value={arenaInput} onChange={handleOnChangeArenaInput} />
                                                    <ButtonCss onlyIcon={true} icon={Check} onClick={() => { handleChange('arena', arenaInput) }} imgHeight='10px' color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setArenaInput(""); setIsArenaOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                                :
                                                <div className={style.skillInfo}>
                                                    <span className={style.skillInfoNum}>{arenaLevel}</span>
                                                    <span>({<span className={style.skillInfoNum}>{arenaValueCopy}</span>} xp)</span>
                                                    {verifyAvatarExpChange && <ButtonCss onlyIcon={true} icon={Edit} onClick={() => { setIsArenaOpen(true) }} imgHeight='15px' color='#155e75' height='18px' width='18px' isRound={true} />}
                                                </div>
                                            }
                                        </div>
                                        <div className={style.skillItem}>
                                            <div className={style.skillTitle}>
                                                <img src={Crafting} className={style.skillIcon} />
                                                <span>Crafting Level: </span>
                                            </div>
                                            {isCraftingOpen ?
                                                <div className={style.skillInfo}>
                                                    <input type='number' placeholder='Valor' className={style.skillInput} value={craftingInput} onChange={handleOnChangeCraftingInput} />
                                                    <ButtonCss onlyIcon={true} icon={Check} onClick={() => { handleChange('crafting', craftingInput) }} imgHeight='10px' color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setCraftingInput(""); setIsCraftingOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                                :
                                                <div className={style.skillInfo}>
                                                    <span className={style.skillInfoNum}>{craftingLevel}</span>
                                                    <span>({<span className={style.skillInfoNum}>{craftingValueCopy}</span>} xp)</span>
                                                    {verifyAvatarExpChange && <ButtonCss onlyIcon={true} icon={Edit} onClick={() => { setIsCraftingOpen(true) }} imgHeight='15px' color='#155e75' height='18px' width='18px' isRound={true} />}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className={style.skillItem}>
                                            <div className={style.skillTitle}>
                                                <img src={Explorer} className={style.skillIcon} />
                                                <span>Explorer Level: </span>
                                            </div>
                                            {isExplorerOpen ?
                                                <div className={style.skillInfo}>
                                                    <input type='number' placeholder='Valor' className={style.skillInput} value={explorerInput} onChange={handleOnChangeExplorerInput} />
                                                    <ButtonCss onlyIcon={true} icon={Check} onClick={() => { handleChange('explorer', explorerInput) }} imgHeight='10px' color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setExplorerInput(""); setIsExplorerOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                                :
                                                <div className={style.skillInfo}>
                                                    <span className={style.skillInfoNum}>{explorerLevel}</span>
                                                    <span>({<span className={style.skillInfoNum}>{explorerValueCopy}</span>} xp)</span>
                                                    {verifyAvatarExpChange && <ButtonCss onlyIcon={true} icon={Edit} onClick={() => { setIsExplorerOpen(true) }} imgHeight='15px' color='#155e75' height='18px' width='18px' isRound={true} />}
                                                </div>
                                            }
                                        </div>
                                        <div className={style.skillItem}>
                                            <div className={style.skillTitle}>
                                                <img src={Social} className={style.skillIcon} />
                                                <span>Social Level: </span>
                                            </div>
                                            {isSocialOpen ?
                                                <div className={style.skillInfo}>
                                                    <input type='number' placeholder='Valor' className={style.skillInput} value={socialInput} onChange={handleOnChangeSocialInput} />
                                                    <ButtonCss onlyIcon={true} icon={Check} onClick={() => { handleChange('social', socialInput) }} imgHeight='10px' color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setSocialInput(""); setIsSocialOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                                :
                                                <div className={style.skillInfo}>
                                                    <span className={style.skillInfoNum}>{socialLevel}</span>
                                                    <span>({<span className={style.skillInfoNum}>{socialValueCopy}</span>} xp)</span>
                                                    {verifyAvatarExpChange && <ButtonCss onlyIcon={true} icon={Edit} onClick={() => { setIsSocialOpen(true) }} imgHeight='15px' color='#155e75' height='18px' width='18px' isRound={true} />}
                                                </div>
                                            }
                                        </div>
                                        <div className={style.skillItem}>
                                            <div className={style.skillTitle}>
                                                <img src={Farmer} className={style.skillIcon} />
                                                <span>Farmer Level: </span>
                                            </div>
                                            {isFarmerOpen ?
                                                <div className={style.skillInfo}>
                                                    <input type='number' placeholder='Valor' className={style.skillInput} value={farmerInput} onChange={handleOnChangeFarmerInput} />
                                                    <ButtonCss onlyIcon={true} icon={Check} onClick={() => { handleChange('farmer', farmerInput) }} imgHeight='10px' color='#78C478' height='17px' width='17px' isRound={true} />
                                                    <ButtonCss onlyIcon={true} icon={Close} imgHeight='8px' onClick={() => { setFarmerInput(""); setIsFarmerOpen(false) }} color='#cc4343' height='17px' width='17px' isRound={true} />
                                                </div>
                                                :
                                                <div className={style.skillInfo}>
                                                    <span className={style.skillInfoNum}>{farmerLevel}</span>
                                                    <span>({<span className={style.skillInfoNum}>{farmerValueCopy}</span>} xp)</span>
                                                    {verifyAvatarExpChange && <ButtonCss onlyIcon={true} icon={Edit} onClick={() => { setIsFarmerOpen(true) }} imgHeight='15px' color='#155e75' height='18px' width='18px' isRound={true} />}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div>
                                <div className={style.avatarSkillsTopContainer}>
                                    <span className={style.skillXp}>Primary XP</span>
                                    <div className={style.avatarSkillsTopContainerRow}>
                                        <div >
                                            <div className={style.skillItem}>
                                                <div className={style.skillTitle}>
                                                    <img src={Primary} className={style.skillPrimaryIcon} />
                                                    <span>Primary Level: </span>
                                                </div>
                                                <div className={style.skillInfo}>
                                                    <span className={style.skillInfoNum}>{primaryLevel}</span>
                                                    <span>({<span className={style.skillInfoNum}>{primaryValue}</span>} xp)</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        })
    }

    if (verifyAvatarPets) {
        tabs.push({
            id: 4,
            tabTitle: 'Pets',
            content:
                (
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                        <BasicTable
                            height='30vh'
                            headers={petsHeaders}
                            items={pets}
                            title='Pets'
                            hasButton={true}
                            onClickBtnItem={handleDeletePet}
                            onClickBtnItemHeader={'Excluir'}
                            onClickBtnItemInfo={'Excluir'}
                            isLoading={petsIsLoading && pets.length === 0}
                            slotFooter={
                                <div className={styles.slotFooter}>
                                    <Button disabled={!petsHasMore || petsIsLoading} text="Carregar Mais" onClick={petsHandleLoadMore} color="cyan" />
                                    {(pets.length === 0) ? "" : <span>Exibindo {0}-{(petsHasMore) ? petsSkip + 200 : pets.length} resultados</span>}
                                </div>
                            }
                        />
                    </div>
                )

        })
    }

    if (verifyAvatarConfig) {
        tabs.push({
            id: 3,
            tabTitle: 'Configurações',
            content: (
                <div className="mt-2 p-2">
                    <textarea rows="4" name="comment" id="comment" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[56vh]"></textarea>
                </div>
            )
        })
    }




    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg'>
            <Tabs
                slotHeader={(
                    <div className='p-2 text-sm flex'>
                        <div className='p-2'>
                            <img src={item.avatarIcon} className='w-16' />
                        </div>
                        <div className={style.avatarHeader}>
                            <b>{item.avatarName}</b>
                            <p>{item.avatarId}</p>
                        </div>
                    </div>
                )}
                tabs={tabs}
            />
        </div>
    )
}
