import React, { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import SidebarDesktop from './components/layout/Sidebar/SidebarDesktop'
import SidebarMobile from './components/layout/Sidebar/SidebarMobile'
import Header from './components/layout/Header'
import { useMainNavigation, userNavigation } from './data/navigation'
import './App.css'

import AppRoutes from './Routes'
import SidebarCards from 'components/layout/Sidebar/SidebarCards'
import LoginPage from 'Pages/auth/Login'
import { isAuthenticated } from 'services/auth'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const mainNavigation = useMainNavigation()

  return (
    <Router>
      <div className="App bg-cyan-800">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {isAuthenticated() && (
          <>
            <SidebarMobile
              navigation={mainNavigation}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen} // Passando o estado para SidebarMobile
            />
            <SidebarDesktop navigation={mainNavigation} />
          </>
        )}

        {isAuthenticated() ? (
          <>
            <div className="lg:pl-44 h-[calc(100vh-64px)]">
              <Header
                userNavigation={userNavigation}
                setSidebarOpen={setSidebarOpen}
              />

              <div className='flex w-full h-full'>
                <main className="py-4 px-4 h-full bg-white sm:rounded-tl-lg flex-1">
                  <AppRoutes />
                </main >
              </div>
            </div >
            <SidebarCards />
          </>
        ) : (
          <LoginPage />
        )}
      </div>
    </Router>
  )
}
